body {
    background-color: rgb(22, 22, 22);
    overflow: hidden;
}


.background {
    min-height: 65vh;
    background-size: cover;
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(/src/assets/background-image.webp);
}

.white-background {
    background-color: #ffffff;
    color: 'black'
}

.heading-text {
    text-align: center;
    font-weight: 800;
    color: #ffffff;
    width: 35%;
    margin: auto;
    padding-top: 20vh;
}

.white-text {
    text-align: center;
    font-weight: 800;
    color: black;
    width: 90%;
    margin: auto;
    padding-top: 3vh;
    padding-bottom: 3vh;
}


.privacy-container {
    height: 75vh;
    padding-bottom: 10px;
    background-color: #ffffff;
}

.privacy-text {
    width: 90%;
    font-family: Arial, sans-serif;
    line-height: 1.6;
    /* margin: 20px; */
    padding: 0;
}

.privacy-text h1 h2 h3 {
    margin-bottom: 20px;
}

.privacy-text ul {
    margin-bottom: 20px;
}

.privacy-text a {
    text-decoration: none;
    color: #0077cc;
}

.privacy-text a:hover {
    text-decoration: underline;
}


@media (max-width : 650px) {
    .background {
        min-height: 25vh;
        /* margin-top: 40px; */
        background-size: contain;
    }

    .heading-text {
        padding-top: 5vh;
    }

    .privacy-text{
        width: 100%;
    }

    .privacy-container{
        height: 68vh;
    }

    .privacy-text {
        width: 80%;
    }
}